import { MESSAGE_TYPE } from "../application/iframe";

export default function (url) {
  var lastHeight = 0;
  function publishHeight() {
    var bodyHeight = getBodyHeight();
    if (bodyHeight == lastHeight) {
      return;
    }
    lastHeight = bodyHeight;
    parent.postMessage({ type: MESSAGE_TYPE, height: bodyHeight }, url);
  }

  function getBodyHeight() {
    // add pixel for shadow border to not display scrollbar
    return document.body.offsetHeight + 16;
  }

  return {
    emitSize: function () {
      window.setInterval(publishHeight, 300);
    },
  };
}
