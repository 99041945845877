export const MESSAGE_TYPE = "__UNIDY_RESIZE_IFRAME";

export default {
  watch: ({ iframeId }) => {
    const iframe = document.getElementById(iframeId);

    function setHeight(height) {
      if (iframe == null) return;
      var new_height = height.toString() + "px";
      iframe.style.height = new_height;
    }

    function handleMessageEvent(event) {
      if (event.data.type !== MESSAGE_TYPE) {
        return;
      }
      setHeight(event.data.height);
    }

    iframe.addEventListener("load", function () {
      iframe.style.display = "block";
      window.addEventListener("message", handleMessageEvent, false);
    });
  },
};
