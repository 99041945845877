export default function(unidyUrl, { clientId, scope = 'openid', responseType = 'id_token' }) {
  const body = document.getElementsByTagName("body")[0];
  const wrapperDivId = 'unidyAuthFrameWrapper';
  const iFrameId = 'unidyAuthFrame';

  let iframe, wrapperDiv;

  const listeners = {
    auth: null,
    fail: null,
    logout: null
  };

  function iFrameSource(target) {
    switch (target) {
      case 'blank':
        return '';
      case "profile":
        return `${unidyUrl}/profile`;
      case "newsletter":
        return `${unidyUrl}/newsletter`;
      default:
        var callback_url = `${document.location.protocol}//${document.location.hostname}`

        if (window.location.port !== '') {
          callback_url = `${callback_url}:${document.location.port}`
        }

        return `${unidyUrl}/oauth/authorize` +
          `?client_id=${clientId}` +
          `&scope=${scope}` +
          `&response_type=${responseType}` +
          `&redirect_uri=${unidyUrl}/oauth/iframe?callback_url=${encodeURI(callback_url)}`;
    }
  }

  function initFrame() {
    if (sessionStorage.idToken) {
      buildIframe('blank');
      listeners.auth({ idToken: sessionStorage.idToken });
    } else {
      buildIframe('login');
    }
  }

  function buildWrapper() {
    wrapperDiv = document.createElement("div");
    wrapperDiv.setAttribute("id", wrapperDivId);

    wrapperDiv.addEventListener("click", function() {
      disableWrapperDiv();
    });
  }

  function activateWrapperDiv() {
    wrapperDiv.style.display = 'block';

    setTimeout(function() {
      wrapperDiv.classList.add("active");
    }, 1); // timeout required to make transition work
  }

  function disableWrapperDiv() {
    wrapperDiv.classList.remove("active")

    setTimeout(function() {
      wrapperDiv.style.display = 'none';
    }, parseFloat(window.getComputedStyle(iframe).transitionDuration) * 1000);
  }

  function buildIframe(target) {
    buildWrapper();

    iframe = document.createElement("iframe");
    iframe.setAttribute("id", iFrameId);
    iframe.setAttribute("src", iFrameSource(target));

    wrapperDiv.appendChild(iframe);
    body.appendChild(wrapperDiv);

    window.addEventListener("message", handleMessage, false);
  }

  function handleMessage(event) {
    if (event.origin !== unidyUrl) {
      return
    }

    const action = event.data.action;
    const listener = listeners[action];
    if (!listener) {
      return;
    }
    listener(event.data);
  }

  return {
    init: initFrame,
    show: function({ target } = { target: "login" }) {
      if (typeof wrapperDiv !== 'undefined') {
        if (iFrameSource(target) !== iframe.getAttribute('src')) {
          iframe.setAttribute("src", iFrameSource(target));

          iframe.addEventListener('load', function() {
            activateWrapperDiv();
          })
        } else {
          activateWrapperDiv();
        }
        return;
      }

      buildIframe(target);

      iframe.addEventListener('load', function() {
        activateWrapperDiv();
      })
    },
    close: function() {
      iframe = document.getElementById(iFrameId);
      wrapperDiv = document.getElementById(wrapperDivId);

      disableWrapperDiv();
    },
    onLogout: function(logoutHandler) {
      listeners.logout = ({ close }) => {
        sessionStorage.clear();
        logoutHandler();
        if (close) { disableWrapperDiv(); }
      }
    },
    onAuth: function(authHandler) {
      listeners.auth = ({ idToken }) => {
        sessionStorage.setItem('idToken', idToken);
        authHandler(idToken);
        disableWrapperDiv();
      };
    }
  };
}